
@media (max-width: 1400px) {
    .intro-box {
        .title {
            h1 {
                font-size: 2.6vw; } } } }

@media (max-width: 960px) {
    .corner-mark {
        left: 1.6rem!important;
        bottom: 1.2rem!important; }
    .auther-info {
        padding: 1.9rem!important;
        padding-bottom: 2.0rem!important; }

    section.intro {
        z-index: 4!important; }

    .second-wrapper,.outro-wrap,.m-container {
        padding: 0 6%!important; }

    .logo-wrapper {
        padding: 2.0rem 6%!important;
        zoom: 0.8; }

    .text-logo-wrapper {
        padding: 3.0rem 6%!important;
        display: none; }

    .intro-box {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 180px 280px 1fr 1fr 1fr 1fr;
        div.menu {
            padding: 0!important;
            text-align: right;
            padding-top: 1.5em!important;
            ul {
                li {
                    line-height: 2.2; } } }
        .title {
            padding: 0!important;
            h1 {
                padding: 0!important;
                margin: 0!important;
                width: 60%;
                float: right;
                font-size: 1.8em; } }
        div.seal {
            .mode-mark {
                width: 200px;
                height: 200px;
                animation: rotation 14s infinite linear;
                display: block;
                position: absolute;
                top: 40vh;
                right: -20vw; } }

        div:nth-child(1),div:nth-child(4),div:nth-child(5) {
            display: none; }

        div.manifesto {
            padding-top: 0;
            p {
                text-align: right;
                display: inline-block;
                letter-spacing: .02rem;
                font-size: 0.84em;
                line-height: 1.54;
                word-wrap: none;
                width: 70%;
                float: right; }
            p.selected {
                padding-top: 2rem; } } }

    ul.navigation-links2 {
        display: none; }

    div.featuring {
        display: none; }


    section#second {
        background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 0%, rgba(241,242,241,1) 97%, rgba(241,242,241,0) 100%);
        padding-top: 3rem;
        .second-wrapper {
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 2rem;
            grid-template-rows: auto;
            grid-template-areas: "whatIs" "systemIcon" "systemText" "communicationIcon" "communicationText" "ability" "ability-info";
            .pt-cell {
                padding: 0!important; }

            &>div:nth-child(4) {
                grid-area: whatIs;
                padding-right: 0;
                h3 {
                    font-size: 1.6em;
                    width: 90%; }
                p {
                    padding-top: 40px;
                    font-size: 1em; } }

            &>div:nth-child(2) {
                grid-area: systemIcon;
                text-align: left;
                img {
                    float: left;
                    margin-top: 30px;
                    margin-left: -78px; } }
            &>div:nth-child(5) {
                grid-area: systemText;
                p {
                    width: 90%;
                    text-align: left;
                    padding-left: 0; } }
            &>div:nth-child(3) {
                grid-area: communicationIcon;
                text-align: left;
                img {
                    float: left;
                    margin-top: 30px;
                    margin-left: -28px; } }

            &>div:nth-child(6) {
                grid-area: communicationText;
                p {
                    width: 90%;
                    text-align: left;
                    padding-left: 0; } }
            &>div:nth-child(7) {
                grid-area: ability;
                .circle-wrapper {
                    padding: 0;
                    padding-top: 3rem;
                    margin: 0;
                    #circle-box {
                        span {
                            font-size: 0.72em; } } } }
            &>div:nth-child(8) {
                grid-area: ability-info;
                padding-top: 2rem;
                height: 550px;
                &>div {
                    text-align: center; }

                h2,p.subtext,p.career-text {

                    text-align: center;
                    padding-left: 0; }
                p.career-text {
                    text-align: center;
                    padding-left: 0;
                    font-size: 0.84em; }
                h2 {
                    display: inline-block;
                    text-align: center; } } } }

    section#third {

        .third-wrapper {
            grid-template-columns: 1fr;
            padding-top: 14rem;
            div.fixed-wrapper {
                margin-left: -12vw;
                margin-right: -12vw; }
            div.core-column {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(5,100px);
                grid-row-gap: 0;
                padding-right: 20%;
                text-align: left;
                img {
                    width: 80px;
                    height: 80px;
                    display: block;
                    position: absolute;
                    top: -10px;
                    left: auto;
                    right: -20vw; } } } }

    section#fourth {
        background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 4%, rgba(241,242,241,1) 100%, rgba(241,242,241,0) 100%);
        padding-top: 6rem;
        padding-bottom: 10rem;
        .fourth-wrapper {
            grid-template-columns: repeat(1,1fr);
            grid-row-gap: 8vh;

            div.cell {
                display: grid;
                grid-template-columns: 140px auto;
                align-items: center;
                img {
                    width: 120px;
                    height: 120px; }

                p {
                    text-align: left;
                    font-size: 0.84em;
                    letter-spacing: .01rem;
                    line-height: 1.5;
                    display: inline-block;
                    position: relative;
                    z-index: 3; }
                b {
                    font-size: 0.9em;
                    display: inline-block;
                    margin-bottom: 7px;
                    text-align: left;
                    line-height: 1.4; } } } }
    section#motto {

        .motto-wrapper {
            .motto-cell {
                font-size: 3rem; }
            .one {
                top: -40%;
                transform: translateX(-30vw) rotate(-35deg); }

            .two {
                top: -25%;
                transform: translateX(-30vw) rotate(-35deg); }
            .three {
                top: -10%;
                transform: translateX(-30vw) rotate(-35deg); }
            .four {
                top: 5%;
                transform: translateX(-30vw) rotate(-35deg); } } }

    section#fifth {
        padding-top: 8rem;
        background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 0%, rgba(241,242,241,1) 99%, rgba(241,242,241,0) 100%);
        padding-bottom: 6rem;
        .fifth-wrapper {
            grid-column-gap: 0;
            div.work-cell {
                h4 {
                    font-size: 1.1em; } }
            div.work-cell:hover {
                h4,p,img.link {
                    opacity: 1;
 }                    // transition: 1s
                h4 {
                    top: 2rem; }
                p {
                    top: 3.3rem; } }
            div.work-cell:nth-child(1) {
                grid-row: 1 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 120%;
                    width: auto;
                    padding-left: 0;
                    padding-top: 10vw; } }
            div.work-cell:nth-child(2) {
                grid-row: 5 / span 6;
                grid-column: 1 / span 12;
                img.main {
                    height: 90%;
                    width: auto;
                    padding-left: 0;
                    padding-top: 0; } }
            div.work-cell:nth-child(3) {
                grid-row: 11 / span 6;
                grid-column: 1 / span 12;
                img.main {
                    height: 80%;
                    width: auto;
                    padding-left: 0;
                    padding-top: 20vw; } }
            div.work-cell:nth-child(4) {
                grid-row: 17 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 50%;
                    width: auto;
                    padding-left: 0;
                    padding-top: 0; } }
            div.work-cell:nth-child(5),div.work-cell:nth-child(6),div.work-cell:nth-child(7),div.work-cell:nth-child(8),div.work-cell:nth-child(10) {
                visibility: hidden; }
            div.work-cell:nth-child(9) {
                grid-row: 21 / span 4;
                grid-column: 1 / span 12; }


            div.work-cell:nth-child(11) {
                grid-row: 25 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 70%;
                    width: auto;
                    padding-left: 0;
                    padding-top: 30px; } }
            div.work-cell:nth-child(12) {
                grid-row: 29 / span 4;
                grid-column: 1 / span 12; }

            div.work-cell:nth-child(13) {
                grid-row: 33 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 110%;
                    width: auto;
                    padding-left: 55px;
                    padding-top: 70px; } }

            div.work-cell:nth-child(14) {
                grid-row: 37 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 120%;
                    width: auto;
                    padding-left: 55px;
                    padding-top: 50px; } }
            div.work-cell:nth-child(15) {
                grid-row: 41 / span 4;
                grid-column: 1 / span 12;
                overflow: hidden;
                img.main {
                    height: auto;
                    width: 140%;
                    padding-left: 60vw;
                    padding-top: 60vw; }
                h4,p,img.link {
                    color: white; } }
            div.work-cell:nth-child(16) {
                grid-row: 45 / span 4;
                grid-column: 1 / span 12;
                overflow: hidden;
                img.main {
                    height: 50%;
                    width: auto; } }
            div.work-cell:nth-child(17) {
                grid-row: 49 / span 4;
                grid-column: 1 / span 12;
                overflow: hidden;
                img.main {
                    height: 50%;
                    width: auto; } }

            div.work-cell:nth-child(18) {
                grid-row: 53 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    padding-top: 80vw; } }


            div.work-cell:nth-child(19) {
                grid-row: 57 / span 4;
                grid-column: 1 / span 12;
                overflow: hidden;
                img.main {
                    height: 70%;
                    width: auto;
                    padding-top: 20px;
                    padding-left: 0; } }

            div.work-cell:nth-child(20) {
                grid-row: 61 / span 4;
                grid-column: 1 / span 12;
                overflow: hidden;
                img.main {
                    height: 50%;
                    width: auto; } }

            div.work-cell:nth-child(21) {
                grid-row: 65 / span 4;
                grid-column: 1 / span 12; }

            div.work-cell:nth-child(22) {
                grid-row: 69 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(23) {
                grid-row: 73 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(24) {
                grid-row: 77 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(25) {
                grid-row: 81 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(26) {
                grid-row: 85 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(27) {
                grid-row: 89 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(28) {
                grid-row: 93 / span 6;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(29) {
                grid-row: 99 / span 5;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(30) {
                grid-row: 104 / span 5;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(31) {
                grid-row: 109 / span 6;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(32) {
                grid-row: 115 / span 3;
                grid-column: 1 / span 12;
                img.main {
                    height: 100%;
                    width: auto; } }
            div.work-cell:nth-child(33) {
                grid-row: 118 / span 4;
                grid-column: 1 / span 12; }
            div.work-cell:nth-child(34) {
                grid-row: 122 / span 4;
                grid-column: 1 / span 12;
                img.main {
                    height: 100%;
                    width: auto; } }

            div.work-cell:nth-child(35) {
                grid-row: 126 / span 4;
                grid-column: 1 / span 12;
                span {
                    color: #121314;
                    font-size: 4.5vw;
                    display: block;
                    width: 200px; } } } }




    section#footer {
        padding-top: 3rem;
        padding-bottom: 8rem;

        .footer-wrapper {


            div.footer-cell:nth-child(1) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column: 1 / span 12; }
            div.footer-cell:nth-child(2) {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column: 1 / span 12; }
            div.footer-cell:nth-child(3) {
                grid-row-start: 4;
                grid-row-end: 6;
                grid-column: 1 / span 12;
                ul#sns {
                    li {
                        float: left;
                        margin-right: 26px;
                        margin-top: 20px; } } } } } }
