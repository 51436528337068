// 定义所有颜色
$color-background: #f1f2f1;
$color-background-yellow: #ccccca;
$color-typeface: #1d1d1d;
$color-background-bright: #f1f2f1;
$color-link-yellow: #ffd700;
$color-type: #121314;
$color-grid: #e5e5e5;

//其他参数
$edge-h: 3rem;
$edge-v: 3rem;



@font-face {
	font-family: 'A11';
	src: url(https://cdn.mionapp.com/website/ec/fonts/A11.otf);
	font-weight: 200;
	font-style: medium; }

// @font-face
// 	font-family: 'G-79'
// 	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Thin.otf)
// 	font-weight: 200
// 	font-style: normal

// @font-face
// 	font-family: 'G-79'
// 	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Light.otf)
// 	font-weight: 300
// 	font-style: normal

@font-face {
	font-family: 'G-79';
	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Regular.otf);
	font-weight: 400;
	font-style: normal; }

// @font-face
// 	font-family: 'G-79'
// 	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Medium.otf)
// 	font-weight: 500
// 	font-style: normal

// @font-face
// 	font-family: 'G-79'
// 	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-SemiBold.otf)
// 	font-weight: 600
// 	font-style: normal

@font-face {
	font-family: 'G-79';
	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Bold.otf);
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: 'G-79';
	src: url(https://cdn.mionapp.com/website/ec/fonts/Kentos-Black.otf);
	font-weight: 800;
	font-style: normal; }

// @font-face
// 	font-family: 'ST-79'
// 	src: url(https://cdn.mionapp.com/website/fonts/ST-79-Black.otf)
// 	font-weight: normal
// 	font-style: normal

// @font-face
// 	font-family: 'ST-69'
// 	src: url(https://cdn.mionapp.com/website/fonts/ST-79-Medium.otf)
// 	font-weight: Medium
// 	font-style: Medium

// @font-face
// 	font-family: 'CT-79'
// 	src: url(https://cdn.mionapp.com/website/fonts/CT-793.ttf)
// 	font-weight: Bold
// 	font-style: Bold



// 辅助定位单位

.set-top-s {
    margin-top: 8px; }
.set-top-m {
    margin-top: 16px; }
.set-top-l {
    margin-top: 24px; }
.set-top-xl {
    margin-top: 32px; }
.set-top-xxl {
    margin-top: 40px; }
.set-top-xxxl {
    margin-top: 48px; }
