.circle-wrapper {
    padding: 0 2rem 0 0;
    margin-top: 8rem; }


#circle-box {
    width: 100%;
    height: 600px;
    position: relative; }

.circle {
    background-color: #ffd700;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
 }    // transition: 0.1s

.circle.is-visible {
    display: block;
    cursor: pointer; }

.circle:hover {
    background-color: #121314;
    .circle-label {
        color: white; } }

.circle-label {
    font-size: 0.87em;
    font-weight: 400;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: rgba(0,0,0,0.7);
    pointer-events: none;
    text-align: center; }


