@import "./define.sass";
// 导航栏-直通
header {
    position: fixed;
    width: 100%;
    z-index: 99;
    pointer-events: none; }

nav.navigation {
    color: $color-typeface; }

.navigation-wrapper {
    position: relative; }


div.featuring {
    pointer-events: auto;
    position: fixed;
    color: #121314;
    font-size: 0.8em;
    padding: $edge-h $edge-v;
    top: 45%;
    line-height: 1.3;
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-50%);

    a {
        color: $color-link-yellow;
        text-decoration: none;
        font-size: 1.3em;
        font-weight: bold; } }

div.main-split {
    border-left: 1px solid white;
    position: fixed;
    left: 10vw;
    bottom: 2em;
    width: 2px;
    height: 70%; }

.logo-wrapper {
    position: fixed;
    padding: $edge-h $edge-v;
    left: 0;
    top: 0;
    pointer-events: auto; }

.ec-logo {
    width: 142px;
    height: 38px; }



.navigation-links-wrapper {}

.corner-mark {
    pointer-events: auto;
    position: fixed;
    left: $edge-h;
    bottom: $edge-v;
    mix-blend-mode: multiply;
    opacity: 0.6;
    transition: 0.4s cubic-bezier(0.420, 0.920, 0.300, 1); }


ul.navigation-links {
    li {
        font-weight: 500;
        float: left;
        &:first-child>a {
            padding-left: 0; }
        a {
            display: inline-block;
            padding: 4.2rem 1.6rem;
            color: $color-typeface; } } }


.text-logo-wrapper {
    position: absolute;
    padding: $edge-h $edge-v;
    right: 0;
    top: 0; }

.mion-text-logo {
    display: inline-block;
    clear: both;
    float: right;
    width: 40px;
    height: 40px;
    margin-top: 2px; }

.auther-info {
    position: fixed;
    padding: $edge-h $edge-v;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;


    svg {

        circle {
            stroke-dasharray: 1000;
            mix-blend-mode: difference;
            stroke: rgba(200,200,200,0.6);
            fill: none;
            stroke-width: 3px;
            stroke-linecap: round;

 } }            //animation: dash 5s linear alternate infinite
    p {
        text-align: right;
        display: inline;
        line-height: 1.54;
        font-weight: 600;
        font-size: 0.66em;
        color: #c1c1c1;
        // background: linear-gradient(transparent 20%, white 20%)
        &:hover {
            color: #dddddd;
            cursor: default; } } }

.is-up {
    transform: rotate(-90deg); }

a.corner-mark-link {
    transform: scale(1,1);
    transition: 0.4s cubic-bezier(0.420, 0.920, 0.300, 1);
    display: block; }
a.corner-mark-link:hover {
    transform: scale(1.2,1.2); }
