@import "./define.sass";
// 全局样式的定义
@import "./import/bourbon/bourbon.scss";
@import "./import/normalize.css";
@import "./import/animate.css";
@import "./import/superb-easing.sass";
// 一些样式扩展
$color-table-border: #7B77CF;
$row-height-L : 12;
$row-height-M : 6;
$row-height-S : 4;
$grid-gap: 2rem;
$easing: cubic-bezier(0.420, 0.920, 0.300, 1);
// 全局样式
@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));
  @return $randomNum; }
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: style; } //解决苹方伪粗体


body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    background: $color-background;
    color: $color-typeface;
    text-justify: ideographic;
    font-size: 15px;
    font-family: G-79, PingFang-TC ,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto , Ubuntu, 'Helvetica Neue', sans-serif; }


::selection {
  background: $color-link-yellow; }

::-moz-selection {
  background: $color-link-yellow; }


@mixin use2x() {
	background-size: 75% 75%; }

a,a:hover,a:active,a:visited,a:link {
	text-decoration: none;
	outline: none; }

.app .m-wrapper {
    padding: 0 24px; }

#loading {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: none;
    background: $color-link-yellow;
 }    // z-index: 1000

.m-container {
    padding: 0 16vw; }

section.intro {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 0; }

#m-background-GL {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 0; }

#mobile-video {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 0;
    display: none;
    object-fit: cover; }

main {
    position: relative; }

// inview animation

@mixin inViewAnimation {
    transform: scale(0.98,0.98);
    transition: opacity 0.6s $easing, transform 0.6s $easing;
    &.is-inview {
        opacity: 1;
        transform: scale(1,1); } }

.background-cosmos {
    background-color: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    min-height: 500px;
    left: 0;
    top: 0;
    z-index: 2; }


.background-overlay {
    background-color: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    min-height: 500px;
    left: 0;
    top: 0;
    z-index: 2;
    pointer-events: none; }

.intro-main-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 3;
    min-height: 46.875rem; }
.intro-main {
    height: 100%; }
.intro-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 25% 25%;

    &>div {
 }        // border-right: 1px solid $color-grid

    &>div.title {
        padding: calc(#{$edge-v} - 4.3%) 0; }
    &>div.menu {
        padding: calc(#{$edge-v} - 0.3rem) 0;
        ul {
            li {
                line-height: 1.7;
                font-weight: 500;
                a {
                    color: $color-type;
                    background: linear-gradient(transparent 50%, rgba(255,255,255,0) 50%,$color-link-yellow  100%); } } } }
    &>div.seal {
        // overflow: hidden
        position: relative;
        .mode-mark {
            width: 300px;
            height: 300px;
            animation: rotation 14s infinite linear;
            display: block;
            position: absolute;
            top: 71%;
            right: -0%; } }

    &>div.introduction {
        position: relative;
        p {
            text-align: right;
            display: inline-block;
            padding-left: 20%;
            font-size: 0.96em;
            padding-top: 40%;
            display: none;
            padding-top: 30%; } }



    &>div.manifesto {
        padding-top: 20vh;
        padding-left: 3vw;
        span {
 }            // background: linear-gradient(transparent 20%, white 20%)
        p {
            text-align: right;
            display: inline-block;
            letter-spacing: .02rem;
            font-size: 0.96em;
            line-height: 1.54; }
        p.selected {
            padding-top: 5rem; } }


    h1 {
        font-size: 2.0vw;
        // font-weight: 600
        line-height: 1.4;
        letter-spacing: -0.05rem;
        font-family: A11;
        text-align: right;
        color: $color-type;
        opacity: 1;


        //text-transform: uppercase
        span.stroke1 {
            color: none;
            -webkit-text-stroke: 0.06rem $color-type;
            -webkit-text-fill-color: transparent; } } }

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg); }

    to {
        -webkit-transform: rotate(359deg); } }


.intro-card {
    min-height: 200px;
    background: #0546ee;
 }    //display: grid

h3 {
    font-size: 1.8em;
    line-height: 1.4;
    letter-spacing: -0.05rem;
    font-family: A11;
    text-align: left;
    color: $color-type; }

b {
    font-weight: 700; }

h2.numbers {
    font-size: 3.5em;
    line-height: 1.4;
    letter-spacing: -0.05rem;
    font-family: A11;
    color: $color-type;
    position: relative;
    sup {
        position: absolute;
        font-size: 0.54em;
        color: #cccccc;
        display: block;
        right: -23px;
        top: 8px; } }

h2.career-number {
    font-size: 6.0em;
    line-height: 1.4;
    letter-spacing: -0.05rem;
    font-family: A11;
    color: none;
    -webkit-text-stroke: 0.06rem $color-type;
    -webkit-text-fill-color: transparent; }

section#second {
    width: 100vw;
    //min-height: 100vh
    //height: auto
    position: relative;
    z-index: 4;
    background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 0%, rgba(241,242,241,1) 90%, rgba(241,242,241,0) 100%);

    .second-wrapper {
        height: 100%;
        display: grid;
        grid-template-columns: auto 25% 25%;
        grid-template-rows: 140px auto auto auto;
        padding-bottom: 8rem;

        &>div {
            // border-right: 1px solid $color-grid
 }            //
        &>div.pt-cell {
            padding: 12rem 0 0 0; }

        &>div.pt-cell-second {
            padding: 3rem 0 0 0; }

        &>div.pt-cell-up {
            padding: 4rem 0 0 0; }
        p {
            text-align: left;
            font-size: 1.1em;
            letter-spacing: .02rem;
            line-height: 1.54; }


        &>div:nth-child(4) {
            padding-right: 10%;
            p {
                padding-top: 5.3rem; } }

        &>div:nth-child(2),div:nth-child(3) {
            img {
                float: right;
                width: 162px;
                height: 120px; } }

        &>div:nth-child(5),div:nth-child(6) {
            p {
                text-align: right;
                padding-left: 22%;
                font-size: 0.9em;
                b {
                    display: inline-block;
                    padding-bottom: 10px;
                    font-size: 1.2em; } } }

        &>div:nth-child(5),div:nth-child(6),div:nth-child(7) {}
        &>div:nth-child(7),div:nth-child(8),div:nth-child(9) {}



        &>div.ability-info {
            padding-top: 10rem;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column: 2 / span 2;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 33%;
            text-align: right;
            p.subtext {
                text-align: right;
                font-size: 0.8em;
                line-height: 1.54; }

            p.career-text {
                text-align: right;
                padding-left: 22%;
                font-size: 0.9em;
                padding-top: 1.1rem; }
            b {
                display: inline-block;
                padding-bottom: 10px;
                font-size: 1.2em; }

            &>div:nth-child(1) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column: 1 / span 1;
                transform: scale(0.98,0.98);
                transition: opacity 0.6s $easing, transform 0.6s $easing;
                &.is-inview {
                    opacity: 1;
                    transform: scale(1,1); } }
            &>div:nth-child(2) {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column: 1 / span 1; }
            &>div:nth-child(3) {
                grid-row-start: 3;
                grid-row-end: 4;
                grid-column: 1 / span 1; }

            &>div:nth-child(4) {
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column: 2 / span 1; }
            &>div:nth-child(5) {
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column: 2 / span 1; }
            &>div:nth-child(6) {
                grid-row-start: 3;
                grid-row-end: 4;
                grid-column: 2 / span 1; } } } }


section#third {
    width: 100vw;
    position: relative;
    z-index: 4;
    padding-top: 2rem;
    background: $color-background;

    .third-wrapper {
        height: 100%;
        display: grid;
        grid-template-columns: 25% 25% auto;

        div.fixed-wrapper {
            position: relative;
            overflow: hidden;
            height: 800px;
            right: 0; }

        div.e-fixed {
            position: absolute;
            top: -100vh;
            bottom: -100vh;
            right: 0;
            left: 0; }

        div.core-image {
            background: url(https://cdn.mionapp.com/website/ec/images/image-core.jpg);
            position: absolute;
            top: -110vh;
            bottom: -100vh;
            right: 0;
            left: 0;
            height: 110vh;
            background-size: cover;
            background-position: center center; }

        div.core-column {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5,20%);
            padding-right: 20%;
            text-align: left;
            &>div,img,p {
                float: left;
                clear: both; }
            &>div {
                position: relative; }
            img {
                width: 120px;
                height: 120px;
                display: block;
                position: absolute;
                top: -30px;
                left: -40px; }

            b {
                font-size: 1.0em;
                display: inline-block;
                margin-bottom: 10px; }
            p {
                text-align: left;
                font-size: 0.87em;
                letter-spacing: .02rem;
                line-height: 1.54;
                display: inline-block;
                position: relative;
                z-index: 3; } } } }

section#fourth {
    width: 100vw;
    position: relative;
    z-index: 4;
    background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 14%, rgba(241,242,241,1) 100%, rgba(241,242,241,0) 100%);

    .fourth-wrapper {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding-top: 9rem;
        padding-bottom: 15rem;
        grid-row-gap: 6vh;
        grid-column-gap: 10rem;


        div.cell {
            display: grid;
            grid-template-columns: 180px auto;
            align-items: center;

            .context {
 }                // padding-right: 8rem
            // justify-items: center
            img {
                width: 140px;
                height: 140px; }
            p {
                text-align: left;
                font-size: 0.9em;
                letter-spacing: .02rem;
                line-height: 1.54;
                display: inline-block;
                position: relative;
                z-index: 3; }
            b {
                font-size: 1.0em;
                display: inline-block;
                margin-bottom: 12px;
                text-align: left;
                line-height: 1.5; } } } }

section#fifth {
    width: 100vw;
    position: relative;
    z-index: 4;
    background: linear-gradient(0deg, rgba(241,242,241,0) 0%, rgba(241,242,241,1) 0%, rgba(241,242,241,1) 98.5%, rgba(241,242,241,0) 100%);

    .fifth-wrapper {
        display: grid;
        position: relative;
        padding-bottom: 10rem;
        padding-top: 15rem;
        grid-row-gap: $grid-gap;
        grid-column-gap: $grid-gap;
        grid-template-columns: repeat(12,1fr);
        grid-auto-rows: 60px;
        div.work-cell:hover {
            h4,p,img.link,span {
                opacity: 1;
 }                // transition: 1s
            h4 {
                top: 2rem; }
            p {
                top: 4rem; }

            span {
                transform: translate(-50%,-50%); }

            img.link {
                top: 2.9rem; }

            img.pdf {
                top: 2.5rem; }
            img.main {
                transform: scale(0.97,0.97) rotate(2deg) translate(-50%,-50%); } }

        div.work-cell {
            cursor: pointer;
            background: none;
            letter-spacing: 0.04rem;
            line-height: 1.2;
            position: relative;

            a {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 100; }

            h4,p,img.link,span {
                color: white;
                position: absolute;
                opacity: 0;
                transition: 0.4s cubic-bezier(0.420, 0.920, 0.300, 1);
                z-index: 9; }
            h4 {
                font-size: 1.5em;
                letter-spacing: -0.03rem;
                font-weight: 500;
                left: 1.9rem;
                top: 4rem;
                font-family: A11; }
            p {
                left: 1.9rem;
                top: 5.7rem;
                font-size: 1.0em; }
            span {
                transform: translate(-50%,0%); }
            img.link {
                width: 16px;
                height: 16px;
                transform: rotate(-90deg);
                right: 3rem;
                top: 4.7rem;
                position: absolute;
                z-index: 8; }
            img.pdf {
                width: 48px;
                height: 23px;
                transform: rotate(0deg); }
            img.main {
                width: 70%;
                height: auto;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 0;
                transform-style: preserve-3d;
                transform: translate(-50%,-50%);
                transition: 0.6s cubic-bezier(0.225, 0.550, 0.525, 0.940);
                transform-origin: 0 25%; } }



        div.work-cell:nth-child(-1n + 9) {
            grid-column: span 4; }

        div.work-cell:nth-child(1) {
            // align-self: end
            // min-height: 56%
            grid-row: 1 / span 8;
            grid-column: 1 / span 6;
            background: #B9AFA6;

            img.main {
                width: 86%; } }

        div.work-cell:nth-child(2) {
            background: #565879;
            grid-row-start: 1;
            grid-row-end: 12;
            grid-column: 7 / span 6;
            img.main {
                width: 80%; } }

        div.work-cell:nth-child(3) {
            grid-row-start: 9;
            grid-row-end: 18;
            grid-column: 1 / span 6;
            background-image: linear-gradient(180deg, #3AA6F5 0%, #6CE1FB 100%);
            img.main {
                width: 110%;
                padding-top: 100px;
                padding-left: 120px; } }

        div.work-cell:nth-child(4) {
            grid-row-start: 12;
            grid-row-end: 18;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: white;
            h4,p,img.link {
                color: #04C641;
                fill: #04C641; }
            img.main {
                width: 50%;

 } }                // mix-blend-mode: multiply
        div.work-cell:nth-child(4):hover {
            img.main {
                transform: scale(1.08,1.08) translate(-50%,-50%); } }
        // mulcloud start
        div.work-cell:nth-child(8):hover,div.work-cell:nth-child(9):hover,div.work-cell:nth-child(10):hover,div.work-cell:nth-child(12):hover,div.work-cell:nth-child(15):hover,div.work-cell:nth-child(16):hover,div.work-cell:nth-child(17):hover,div.work-cell:nth-child(20):hover,div.work-cell:nth-child(21):hover,,div.work-cell:nth-child(22):hover,,div.work-cell:nth-child(24):hover,div.work-cell:nth-child(25):hover,div.work-cell:nth-child(27):hover,div.work-cell:nth-child(28):hover,div.work-cell:nth-child(29):hover,div.work-cell:nth-child(30):hover,div.work-cell:nth-child(31):hover,div.work-cell:nth-child(32):hover,div.work-cell:nth-child(33):hover,div.work-cell:nth-child(34):hover,div.work-cell:nth-child(35):hover {
            img.main {
                transform: scale(1.03,1.03) translate(-50%,-50%); } }
        div.work-cell:nth-child(18):hover,div.work-cell:nth-child(19):hover,div.work-cell:nth-child(23):hover,div.work-cell:nth-child(26):hover {
            img.main {
                transform: scale(1.03,1.03) translate(-50%,-45%); } }
        div.work-cell:nth-child(5):hover,div.work-cell:nth-child(6):hover,div.work-cell:nth-child(7):hover {
            img.main {
                transform: scale(1,1) translate(-50%,-50%); } }
        div.work-cell:nth-child(5) {
            grid-row-start: 24;
            grid-row-end: 27;
            grid-column: 0 / span 4;
            min-height: 100%;
            background: none;
            h4,p,img.link {
                color: #121314; } }
        div.work-cell:nth-child(6) {
            grid-row-start: 21;
            grid-row-end: 24;
            grid-column: 5 / span 4;
            min-height: 100%;
            background: none;
            h4,p,img.link {
                color: #121314; } }
        div.work-cell:nth-child(7) {
            grid-row-start: 24;
            grid-row-end: 27;
            grid-column: 5 / span 4;
            min-height: 100%;
            background: none;
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(8) {
            grid-row-start: 18;
            grid-row-end: 21;
            grid-column: 5 / span 8;
            min-height: 100%;
            overflow: hidden;
            background: none;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(9) {
            grid-row-start: 18;
            grid-row-end: 24;
            grid-column: 0 / span 4;
            min-height: 100%;
            overflow: hidden;
            img.main {
                height: 100%;
                width: auto; } }
        div.work-cell:nth-child(10) {
            grid-row-start: 21;
            grid-row-end: 27;
            grid-column: 9 / span 4;
            min-height: 100%;
            background: white;
            overflow: hidden;
            img.main {
                height: 100%;
                width: auto; } }
        //mulcloud end
        //kentos
        div.work-cell:nth-child(11) {
            grid-row-start: 27;
            grid-row-end: 34;
            grid-column: 1 / span 6;
            min-height: 100%;

            background: #56a646;
            img.main {
                height: auto;
                width: 82%;
                padding-top: 70px; }
            h4,p,img.link {
                color: white; } }


        div.work-cell:nth-child(12) {
            grid-row-start: 27;
            grid-row-end: 34;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: #BACAC8;
            overflow: hidden;
            mix-blend-mode: multiply;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: #121314; } }


        //kentos end
        // Lsquare
        div.work-cell:nth-child(13) {
            grid-row-start: 34;
            grid-row-end: 41;
            grid-column: 1 / span 6;
            min-height: 100%;
            background: #BACAC8;
            img.main {
                height: auto;
                width: 80%;
                padding-left: 110px;
                padding-top: 130px; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(14) {
            grid-row-start: 34;
            grid-row-end: 41;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: #c9412e;
            img.main {
                height: auto;
                width: 90%;
                padding-left: 80px;
                padding-top: 70px; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(15) {
            grid-row-start: 41;
            grid-row-end: 49;
            grid-column: 1 / span 12;
            min-height: 100%;
            background: #f0cc28;
            img.main {
                height: auto;
                width: 70%;
                padding-top: 20px; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(16) {
            grid-row-start: 49;
            grid-row-end: 56;
            grid-column: 1 / span 7;
            min-height: 100%;
            background: #00C662;
            img.main {
                height: auto;
                width: 40%;
                padding-top: 20px; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(17) {
            grid-row-start: 49;
            grid-row-end: 56;
            grid-column: 8 / span 5;
            min-height: 100%;
            background: #486C60;
            img.main {
                height: auto;
                width: 60%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(18) { //poe
            grid-row-start: 56;
            grid-row-end: 61;
            grid-column: 1 / span 4;
            min-height: 100%;
            background: #DFDBD4;
            overflow: hidden;
            img.main {
                height: auto;
                width: 66%;
                padding-top: 16rem; }
            h4,p,img.link {
                color: #121314; } }

        div.work-cell:nth-child(19) {
            grid-row-start: 58;
            grid-row-end: 63;
            grid-column: 5 / span 4;
            min-height: 100%;
            background: #7B93AA;
            overflow: hidden;
            img.main {
                height: 70%;
                width: auto;
                padding-left: 10rem;
                padding-top: 30px; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(20) {
            grid-row-start: 60;
            grid-row-end: 65;
            grid-column: 9 / span 4;
            min-height: 100%;
            background: #AAC8C6;
            img.main {
                height: auto;
                width: 50%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(21) { //moneyback
            grid-row-start: 63;
            grid-row-end: 68;
            grid-column: 1 / span 8;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: 100%;
                width: auto; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(22) { //theorist
            grid-row-start: 65;
            grid-row-end: 70;
            grid-column: 9 / span 4;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: #121314; } }

        div.work-cell:nth-child(23) { //palor
            grid-row-start: 68;
            grid-row-end: 73;
            grid-column: 5 / span 4;
            min-height: 100%;
            background: #3F3F3F;
            overflow: hidden;
            img.main {
                height: auto;
                width: 50%;
                width: 66%;
                padding-top: 16rem; }
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(24) { //sp
            grid-row-start: 70;
            grid-row-end: 75;
            grid-column: 1 / span 4;
            min-height: 100%;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(25) { //chapter
            grid-row-start: 73;
            grid-row-end: 81;
            grid-column: 5 / span 8;
            min-height: 100%;
            background: #BACAC8;
            overflow: hidden;
            mix-blend-mode: multiply;
            img.main {
                height: 100%;
                width: auto; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(26) { //atem
            grid-row-start: 75;
            grid-row-end: 81;
            grid-column: 1 / span 4;
            min-height: 100%;
            background: #486C60;
            overflow: hidden;
            img.main {
                height: auto;
                width: 66%;
                padding-top: 16rem; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(27) { //extra start
            grid-row-start: 81;
            grid-row-end: 87;
            grid-column: 1 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: 100%;
                width: auto; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(28) { //extra start
            grid-row-start: 81;
            grid-row-end: 91;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(29) { //extra start
            grid-row-start: 87;
            grid-row-end: 96;
            grid-column: 1 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(30) { //extra start
            grid-row-start: 91;
            grid-row-end: 98;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }

        div.work-cell:nth-child(31) { //extra start
            grid-row-start: 96;
            grid-row-end: 101;
            grid-column: 1 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: 100%;
                width: auto; }
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(32) { //extra start
            grid-row-start: 98;
            grid-row-end: 102;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(33) { //extra start
            grid-row-start: 101;
            grid-row-end: 107;
            grid-column: 1 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: white; } }
        div.work-cell:nth-child(34) { //extra start
            grid-row-start: 102;
            grid-row-end: 107;
            grid-column: 7 / span 6;
            min-height: 100%;
            background: none;
            overflow: hidden;
            img.main {
                height: auto;
                width: 100%; }
            h4,p,img.link {
                color: #121314; } }

        div.work-cell:nth-child(35) { //Portfolio
            grid-row-start: 107;
            grid-row-end: 113;
            grid-column: 1 / span 12;
            min-height: 100%;
            background: none;
            overflow: hidden;
            h4,p,img.link {
                color: #121314; }
            span {
                color: #121314;
                font-size: 1.7em;
                letter-spacing: -0.05rem;
                display: block;
                position: absolute;
                width: 300px;
                height: 80px;
                left: 50%;
                font-family: A11;
                top: 50%;
                z-index: 9;
                mix-blend-mode: hard-light; } } } }
section#motto {
    width: 100vw;
    position: relative;

    z-index: 0;
    background: none;
    background-size: cover;

    .motto-wrapper {
        position: relative;
        height: 100vh;

        .motto-cell {
            position: absolute;
            font-size: 5rem;
            white-space: nowrap;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1;

            &:nth-child(odd) {
                span {
                    background-color: white;
                    color: #121314;
                    opacity: 1; } }

            span {
                display: block;
                color: white;
                background-color: #121314;
                white-space: nowrap;
                padding: 0 20px;
                letter-spacing: 0.1rem;
                mix-blend-mode: multiply; } }
        .one {
            top: 24%;
            transform: translateX(-30vw) rotate(-28deg); }

        .two {
            top: 36%;
            transform: translateX(-30vw) rotate(-28deg); }
        .three {
            top: 48%;
            transform: translateX(-30vw) rotate(-28deg); }
        .four {
            top: 60%;
            transform: translateX(-30vw) rotate(-28deg); }
        // .five
        //     top: 0%
        // .six
        //     top: 12%
        // .seven
        //     top: 24%
        // .eight
        //     top: 36%
        // .nine
        //     top: 48%
        // .ten
        //     top: 60%
        // .eleven
        //     top: 72%
        // .twelve
        //     top: 84%
        // .thirteen
        //     top: 96%
        // .fourteen
        //     top: 108%
        // .fifteen
        //     top: 120%
        // .sixteen
 } }        //     top: 132%


section#footer {
    width: 100vw;
    position: relative;
    z-index: 0;
    background: #121314;
    z-index: 9;

    .footer-wrapper {
        display: grid;
        position: relative;
        grid-row-gap: $grid-gap;
        grid-column-gap: $grid-gap;
        grid-template-columns: repeat(12,1fr);
        grid-auto-rows: 60px;

        div.footer-cell {
            ul {
                li {
                    margin-bottom: 1.2em;
                    text-align: left;
                    color: white;
                    a {
                        color: white;
                        font-weight: 500;
                        text-decoration: underline; }
                    a {
                        display: inline-block;
                        transition: 0.4s cubic-bezier(0.420, 0.920, 0.300, 1);
                        display: block;
                        text-decoration: underline;
                        text-decoration-color: rgba(0,0,0,0); }
                    a:hover {
                        transform: translateY(-2px);
                        text-decoration-color: $color-link-yellow; } } } }
        div.footer-cell:nth-child(1) { //extra start
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column: 1 / span 3;
            min-height: 100%;
            color: white;
            p {
                font-weight: 700;
                font-size: 1.1em;
                text-transform: uppercase; }
            sup {
                font-size: 0.57em; } }
        div.footer-cell:nth-child(2) { //extra start
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column: 7 / span 3;
            min-height: 100%; }




        div.footer-cell:nth-child(3) { //extra start
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column: 10 / span 3;
            min-height: 100%;

            ul#sns {
                li {
                    float: left;
                    margin-right: 16px;
                    img {
                        width: 26px;
                        height: 26px; }
                    a {
                        transform: scale(1,1);
                        transition: 0.4s cubic-bezier(0.420, 0.920, 0.300, 1);
                        display: block;
                        opacity: 0.2; }
                    a:hover {
                        opacity: 1;
                        transform: scale(1.2,1.2); } } } } }




    .padding-wrapper {
        padding-top: 7rem;
        padding-bottom: 5.5rem; } }


p {
    letter-spacing: -0.01rem; }

.p-cn {
    letter-spacing: -.03rem;
    line-height: 1.74!important;
    font-size: 1.1em!important; }



.hide {
    visibility: hidden!important;
    display: none!important; }

.beian {
    font-size: 0.9em;
    color: #e7e7e7!important; }

// Media Support Phones
@import "./mobile.sass";

.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0.5;
    z-index: 999;
    .c-scrollbar:hover {
        transform: scaleX(1.45); }
    .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
        opacity: 1; } }

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
    .has-scroll-dragging .c-scrollbar_thumb {
        cursor: -webkit-grabbing;
        cursor: grabbing; } }

.fit-image {
    img.main {
        height: 100%!important;
        width: 100%!important;
        object-fit: cover!important; } }
